<template>
  <div class="page">
    <div
      v-if="routeFrom && (routeFrom.name === 'Accounts')"
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($tc('words.accountsManagement')) }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Users' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('userDetail.usersList') }}
      </span>
    </div>
    <div
      class="add-user-form"
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
      />
      <b-container fluid>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-row>
            <b-col
              cols="8"
              class="detail-forms"
            >
              <h2>{{ $t('addUser.title') }}</h2>
              <form>
                <h3 class="line-header">
                  <img
                    src="@/assets/icons/face_user.svg"
                    alt="Icon face user"
                  > {{ capitalizeFirstLetter($tc('words.user')) }}
                </h3>

                <!-- ADDITIONNAL_ID -->
                <div
                  v-if="isFieldVisible('additional_id', formConfig.hiddenFields)"
                  class="form-row"
                >
                  <div class="form-group col-6">
                    <ValidationProvider
                      v-slot="{ classes, errors }"
                      ref="additional_id"
                      :rules="isFieldRequired('additional_id', formConfig.requiredFields)"
                    >
                      <div
                        class="control"
                        :class="classes"
                      >
                        <label :class="isFieldRequired('additional_id', formConfig.requiredFields)">
                          {{ $t('forms.user.idComp.label') }}
                        </label>
                        <p class="label-help">
                          {{ $t('forms.user.idComp.help') }}
                        </p>
                        <input
                          v-model="form.additional_id"
                          v-sanitize
                          class="form-control"
                          type="text"
                          :placeholder="$t('forms.user.idComp.label')"
                        >
                      </div>
                      <span class="form-errors">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-6 right">
                    <!-- EMAIL_NOTIFICATIONS -->
                    <div class="form-check">
                      <input
                        v-model="form.emailNotifications"
                        type="checkbox"
                      >
                      <label class="form-check-label">
                        {{ $t('forms.user.emailNotifications') }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <!-- LAST_NAME -->
                  <div
                    v-if="isFieldVisible('last_name', formConfig.hiddenFields)"
                    class="form-group col-6"
                  >
                    <label :class="isFieldRequired('last_name', formConfig.requiredFields)">
                      {{ $t('forms.user.lastname') }}
                    </label>
                    <ValidationProvider
                      v-slot="{ classes, errors }"
                      ref="last_name"
                      :rules="isFieldRequired('last_name', formConfig.requiredFields)"
                    >
                      <div
                        class="control"
                        :class="classes"
                      >
                        <input
                          v-model="form.last_name"
                          v-sanitize
                          class="form-control"
                          type="text"
                          :placeholder="$t('forms.user.lastname')"
                        >
                        <span class="form-errors">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!-- FIRST_NAME -->
                  <div
                    v-if="isFieldVisible('first_name', formConfig.hiddenFields)"
                    class="form-group col-6"
                  >
                    <label :class="isFieldRequired('first_name', formConfig.requiredFields)">
                      {{ $t('forms.user.firstname') }}
                    </label>
                    <ValidationProvider
                      v-slot="{ classes, errors }"
                      ref="first_name"
                      :rules="isFieldRequired('first_name', formConfig.requiredFields)"
                    >
                      <div
                        class="control"
                        :class="classes"
                      >
                        <input
                          v-model="form.first_name"
                          v-sanitize
                          class="form-control"
                          type="text"
                          :placeholder="$t('forms.user.firstname')"
                        >
                        <span class="form-errors">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-row">
                  <!-- EMAIL -->
                  <div
                    v-if="isFieldVisible('email', formConfig.hiddenFields)"
                    class="form-group col-6"
                  >
                    <label :class="isFieldRequired('email', formConfig.requiredFields)">
                      {{ $t('forms.user.email') }}
                    </label>
                    <ValidationProvider
                      v-slot="{ classes, errors }"
                      ref="email"
                      :rules="`${isFieldRequired('email', formConfig.requiredFields)}|email`"
                    >
                      <div
                        class="control"
                        :class="classes"
                      >
                        <input
                          v-model="form.email"
                          v-sanitize
                          class="form-control"
                          type="email"
                          :placeholder="$t('forms.user.email')"
                        >
                        <span class="form-errors">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!-- PHONE -->
                  <div
                    v-if="isFieldVisible('phone', formConfig.hiddenFields)"
                    class="form-group col-6"
                  >
                    <label :class="isFieldRequired('phone', formConfig.requiredFields)">
                      {{ $t('forms.user.phone') }}
                    </label>
                    <ValidationProvider
                      v-slot="{ classes, errors }"
                      ref="phone"
                      :rules="isFieldRequired('phone', formConfig.requiredFields)"
                    >
                      <div
                        class="control"
                        :class="classes"
                      >
                        <input
                          v-model="form.phone_number"
                          v-sanitize
                          class="form-control"
                          type="text"
                          :placeholder="$t('forms.user.phone')"
                        >
                        <span class="form-errors">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- COMMENTS -->
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>{{ $t('forms.user.reason') }}</label>
                    <textarea
                      v-model="form.comments"
                      v-sanitize
                      class="form-control"
                      rows="1"
                    />
                  </div>
                </div>

                <h3 class="line-header">
                  <img
                    src="@/assets/icons/company.svg"
                    alt="Icon company"
                  > {{ capitalizeFirstLetter($tc('words.organisation')) }}
                </h3>
                <UserOrgForm
                  v-for="organisation of organisations"
                  :key="organisation.position"
                  :usergroup="organisation"
                  @allowAdd="setCanAddOrganisation"
                  @change="updateOrganisation"
                  @delete="removeOrganisation"
                />
                <button
                  class="add-button"
                  :class="{
                    disabled: !canAddOrganisation
                  }"
                  @click.prevent="addOrganisation"
                >
                  <b-icon-plus />
                  {{ $t('addUser.addOrganisation') }}
                </button>

                <!-- Groupe d'utilisateurs -->
                <h3 class="line-header">
                  <img
                    src="@/assets/icons/user-group.svg"
                    alt="Icon company"
                  > {{ capitalizeFirstLetter($tc('words.userGroup')) }}
                </h3>
                <UserGroupForm
                  v-for="group of groups"
                  :key="`group-${group.position}`"
                  :usergroup="group"
                  @allowAdd="setCanAddGroup"
                  @change="updateGroup"
                  @delete="removeGroup"
                />
                <button
                  class="add-button"
                  :class="{
                    disabled: !canAddGroup
                  }"
                  @click.prevent="addGroup"
                >
                  <b-icon-plus />
                  {{ $t('addUser.addGroup') }}
                </button>
              </form>
            </b-col>
            <b-col
              cols="4"
              class="detail-card"
            >
              <div class="card-footer">
                <UserCard
                  :user="form"
                />
                <div class="side-footer">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="handleSubmit(checkUsergroups)"
                  >
                    {{ $t('buttons.save') }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </ValidationObserver>
      </b-container>
    </div>
  </div>
</template>

<script>
import UserCard from '@/components/Cards/UserCard.vue';
import UserOrgForm from '@/components/Forms/UserOrgForm.vue';
import UserGroupForm from '@/components/Forms/UserGroupForm.vue';

import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex';

import { usernamify, capitalizeFirstLetter } from '@/utils';

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure
} from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
extend('email', {
  ...email,
  message: 'Veuillez entrer une adresse email valide'
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

const LABEL_ID_COMP = process.env.VUE_APP_LABEL_ID_COMP;

export default {
  name: 'AddUser',

  components: {
    UserCard,
    UserOrgForm,
    UserGroupForm,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      routeFrom: null,
      loading: false,
      //form
      form: {
        additional_id: null,
        emailNotifications: false,
        last_name: null,
        first_name: null,
        email: null,
        phone_number: null,
        comments: null
      },
      organisations: [],
      groups: [],
      canAddOrganisation: true,
      canAddGroup: true,
      labelIdComp: LABEL_ID_COMP
    };
  },

  computed: {
    ...mapState('users', [
      'usersError',
      'lastCreatedUser'
    ]),
    ...mapState('organisations', [
      'organisationsList',
    ]),
    ...mapState('form', [
      'currentFormId',
      'currentForm'
    ]),

    formConfig() {
      return this.$config.forms.user;
    },
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.SET_CURRENT_FORM({
          id: 'addUser',
          form: newValue
        });
      }
    },
    usersError(newValue) {
      if (newValue) {
        for (const [key, value] of Object.entries(newValue)) {
          this.$refs[key].applyResult({
            errors: value,
            valid: false,
            failedRules: {},
          });
        }
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    if (this.currentFormId === 'addUser') {
      this.form = {
        ...this.form,
        ...this.currentForm
      };
    }
  },

  methods: {
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('users', [
      'SET_LAST_CREATED_USER'
    ]),
    ...mapActions('users', [
      'CREATE_USER',
      'GET_USERS_LIST'
    ]),

    checkUsergroups() {
      const usergroups = this.organisations.concat(this.groups);
      let uncompleteUsergroups = [];
      for (const usergroup of usergroups) {
        if (usergroup.role === null || usergroup.roleStatus === null) {
          uncompleteUsergroups.push(usergroup);
        }
      }
      if (uncompleteUsergroups.length > 0) {
        const uncompleteUsergroupsNames = uncompleteUsergroups.map(el => el.usergroup.display_name).join(', ');
        Swal.fire({
          position: 'center',
          heightAuto: false,
          icon: 'warning',
          html: `<p>Vous avez rattaché l'utilisateur aux organisation(s)/groupe(s) suivants:</p>
            <p><b>${uncompleteUsergroupsNames}</b></p>
            <p>mais vous n'avez pas indiqué le rôle ou le statut
            pour ce(s) rattachement(s). Ces modifications ne seront pas prises en compte.</p>
            <p>Voulez-vous poursuivre?</p>
          `,
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          showConfirmButton: true,
          confirmButtonText: 'Confirmer',
          confirmButtonColor: '#187CC6',
        }).then((result) => {
          if (result.isCancelled) {
            return false;
          } else if (result.isConfirmed) {
            this.addUser();
          }
        });
      } else {
        this.addUser();
      }
    },

    addUser() {
      this.loading = true;
      const usergroups = this.organisations.concat(this.groups);
      const cleanedUsergroups = usergroups.map((el) => {
        if (el.role !== null && el.roleStatus !== null && el.usergroup.id) {
          return {
            role: el.role,
            status: el.roleStatus,
            usergroup: {
              id: el.usergroup.usergroup ? el.usergroup.usergroup.id : el.usergroup.id
            }
          };
        } else {
          return null;
        }
      }).filter(el => { return el !== null; });
      const data = {
        username: usernamify(this.form.first_name.charAt(0).concat('', this.form.last_name)),
        additional_id: this.form.additional_id,
        email_notification: this.form.emailNotifications,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone_number: this.form.phone_number,
        comments: this.form.comments,
        is_active: true,
        usergroup_roles: cleanedUsergroups
      };
      this.CREATE_USER(data)
        .then(() => {
          if (this.lastCreatedUser) {
            this.SET_LAST_CREATED_USER(null);
            this.SET_CURRENT_FORM({
              id: null,
              form: {}
            });
            this.GET_USERS_LIST({
              direction: null,
              field: null
            }).then(() => {
              this.loading = false;
              this.$router.push({ name: 'Users' });
            });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.form = this.currentForm;
          this.loading = false;
        });
    },

    setCanAddOrganisation(e) {
      this.canAddOrganisation = e;
    },

    addOrganisation() {
      const position = this.organisations.length + 1;
      this.organisations.push({
        position: position,
        role: null,
        roleStatus: null,
        usergroup: {}
      });
      this.canAddOrganisation = false;
    },

    updateOrganisation(e) {
      const index = this.organisations.findIndex(el => el.position === e.position);
      this.organisations[index] = {
        position: e.position,
        role: e.data.role,
        roleStatus: e.data.roleStatus,
        usergroup: e.data.organisation
      };
    },

    removeOrganisation(e) {
      const index = e - 1;
      this.organisations.splice(index);
      this.canAddOrganisation = true;
    },

    setCanAddGroup(e) {
      this.canAddGroup = e;
    },

    addGroup() {
      const position = this.groups.length + 1;
      this.groups.push({
        position: position,
        role: null,
        roleStatus: null,
        usergroup: {}
      });
      this.canAddGroup = false;
    },

    updateGroup(e) {
      const index = this.groups.findIndex(el => el.position === e.position);
      this.groups[index] = {
        position: e.position,
        role: e.data.role,
        roleStatus: e.data.roleStatus,
        usergroup: e.data.group
      };
    },

    removeGroup(e) {
      const index = e - 1;
      this.groups.splice(index, 1);
      this.canAddGroup = true;
    },
  }
};
</script>

<style lang="less" scoped>

.add-user-form {
  margin-left: max(2em, auto);
  margin-right: max(2em, auto);
}

form {
  max-width: 1000px;
}

h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

// .btn {
//   font-size: 1.3em;
//   font-weight: 500;
// }

</style>
